@import "../utils/rem-calc";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/_breakpoints";

// industrie_sectors

.mat-dialog-title {

  .header--close {
    display: block;
    margin-left: auto;
    cursor: pointer;
  }
}

.mat-dialog-container {

  margin: rem-calc(15px);

  .mat-dialog-actions {
    display: flex;
    flex-direction: column !important;
    align-items: stretch;
  }

  .mat-dialog-title + .mat-dialog-content {
    padding-top: 0;
  }

  .mat-dialog-content {
    padding: 0;
    margin: 0;
    position: relative;

    .header--close {
      position: sticky;
      cursor: pointer;
      top: 0;
    }
  }

  .mat-dialog-actions {
    margin-top: rem-calc(40px);

    .btn:not(:last-child) {
      margin-bottom: rem-calc(10px);
    }
  }

  @include media-breakpoint-up(md) {

    margin: 0;

    .mat-dialog-actions {
      flex-direction: row !important;
      align-items: center;

      .btn,
      .btn:not(:last-child) {
        flex: 1;
        margin-bottom: 0;
        max-width: rem-calc(236px);
      }
    }

    .mat-dialog-actions button {
      max-width: rem-calc(285px);
    }

    .mat-dialog-content {
      padding: rem-calc(36px);
    }
  }
}
